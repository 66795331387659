import { useParams } from 'react-router-dom'

import icoImg from 'assets/images/ico_no_event.png'
import useGetEventList from 'hooks/useGetEventList'
import { v4 as uuid } from 'uuid'

import NoContent from 'components/NoContent'
import Pagination from 'components/Pagination'

import ListItemDivision from './ListItemDivision'
import TabMain from './TabMain'

import 'assets/styles/event.scss'

export default function EventProgress() {
  const { currentSeq } = useParams()
  const [progressData, totalPages] = useGetEventList(currentSeq, 'PROGRESS')

  return (
    <>
      <TabMain activeTab={'PROGRESS'} />
      <section className="list-col list">
        <div className="inner-col">
          <div className="list-box">
            <ul className="tab-content progress-event active">
              {progressData.length > 0 ? (
                progressData.map((item, idx) => (
                  <li key={uuid()}>
                    <ListItemDivision data={item} />
                  </li>
                ))
              ) : (
                <NoContent icoImg={icoImg} titleMsg={'등록된 이벤트가 없습니다.'} />
              )}
            </ul>
            {progressData.length > 0 ? (
              <Pagination
                type={'SEQ'}
                totalPages={totalPages}
                limitPage={5}
                maxNum={5}
                minNum={0}
                pathName={'/event/list'}
                currentSeq={currentSeq}
              />
            ) : null}
          </div>
        </div>
      </section>
    </>
  )
}
