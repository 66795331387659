import React from 'react'

import s from './Chat.module.scss'
import ChatFeedList from './ChatFeedList'
import ChatTopFaq from './ChatTopFaq'

export default function ChatFeedContainer({ isAiResponse, pageType, addToFeed }) {
  return (
    <div className={s.chatFeed}>
      <ChatTopFaq />
      <ChatFeedList isAiResponse={isAiResponse} pageType={pageType} addToFeed={addToFeed} />
    </div>
  )
}
