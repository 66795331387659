import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useSearchParams } from 'react-router-dom'

import cx from 'classnames'
import MobileTitleBar from 'pages/service/components/MobileTitleBar'
import { webviewHistoryBack, webviewHistorySave } from 'webview/bridge'
import CustomerTitleBar from 'webview/service/components/CustomerTitleBar'

import s from './components/Chat.module.scss'
import ChatContainer from './components/ChatContainer'
import ChatFooter from './components/ChatFooter'

import useAiFeeds from './hooks/useAiFeeds'

export default function AiFaq({ pageType, isPlatform }) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const gubun = searchParams.get('service')
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const { addToFeed, isAiResponse, postPromptMessage, resetFeedList } = useAiFeeds({ gubun })
  const [terminate, setTerminate] = useState(false)

  const pressBackButton = () => {
    navigate('/service/center')
  }

  useEffect(() => {
    window['wingBannerDisplay']()

    return () => resetFeedList()
  }, [pageType, resetFeedList])

  useEffect(() => {
    const footerEl = document.querySelector('footer')

    if (footerEl && !isPlatform) {
      footerEl.style.setProperty('display', 'none')
    }

    if (isPlatform) {
      webviewHistorySave()
    }

    return () => {
      if (footerEl && !isPlatform) {
        footerEl.style.removeProperty('display')
      }
    }
  }, [isPlatform])

  return (
    <>
      {isMobile && !isPlatform && <MobileTitleBar title="AI 고객센터" onClick={pressBackButton} />}
      {isPlatform && !terminate && (
        <CustomerTitleBar
          title="AI 상담원"
          onBtnClick={() => navigate('/webview/service/center')}
        />
      )}
      <article
        className={cx(s.chatLayout, {
          [s.nadooLayout]: pageType !== 'srr',
          [s.webview]: isPlatform
        })}
      >
        <ChatContainer isAiResponse={isAiResponse} pageType={pageType} addToFeed={addToFeed} />
        <ChatFooter
          addToFeed={addToFeed}
          postPromptMessage={postPromptMessage}
          isAiResponse={isAiResponse}
          isPlatform={isPlatform}
          setTerminate={setTerminate}
        />
      </article>
    </>
  )
}
