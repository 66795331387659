import React from 'react'

import FaqList from './FaqList'
import Pagination from './Pagination'
import useFaqPagination from '../hooks/useFaqPagination'
import useGetFaqFilter from '../hooks/useGetFaqFilter'

import s from '../Service.module.scss'

function IcoAlert() {
  return (
    <div className={s.emptyIcon}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
      >
        <circle cx="30.375" cy="30" r="25.1" stroke="#CFCFDA" strokeWidth="3.8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.375 17.625C31.6176 17.625 32.625 18.8521 32.625 20.3659V31.3841C32.625 32.8979 31.6176 34.125 30.375 34.125C29.1324 34.125 28.125 32.8979 28.125 31.3841V20.3659C28.125 18.8521 29.1324 17.625 30.375 17.625Z"
          fill="#CFCFDA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.75 39.75C27.75 38.3003 28.9194 37.125 30.3619 37.125H30.3881C31.8306 37.125 33 38.3003 33 39.75C33 41.1997 31.8306 42.375 30.3881 42.375H30.3619C28.9194 42.375 27.75 41.1997 27.75 39.75Z"
          fill="#CFCFDA"
        />
      </svg>
    </div>
  )
}

function NotResult() {
  return (
    <div className={s.searchEmpty}>
      <div className={s.notResult}>
        <IcoAlert />
        <p>검색 결과가 없습니다.</p>
      </div>
      <div className={s.searchTip}>
        <strong>검색 TIP</strong>
        <ul>
          <li>단어의 철자가 정확한지 확인해 주세요.</li>
          <li>검색어의 단어 수를 줄이거나 다른 검색어로 검색해 보세요.</li>
          <li>일반적인 단어로 다시 검색해 보세요.</li>
        </ul>
      </div>
    </div>
  )
}

export default function FaqSearchList({ keyword }) {
  const { faqList } = useGetFaqFilter(keyword)
  const { currentPage, totalPages, currentData, handlePageChange } = useFaqPagination(faqList, 10)
  return (
    <div className={s.faqSearch}>
      <hgroup className={s.searchTitle}>
        <h4>
          <span>{keyword}</span>에 대한 검색결과
        </h4>
        <span className={s.searchCount}>총 {faqList.length}개</span>
      </hgroup>
      {faqList.length < 1 ? <NotResult /> : <FaqList list={currentData} />}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  )
}
