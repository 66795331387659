import { API_HOST } from 'common/config'
import { getCookie, removeCookie, setCookie } from 'common/Cookie'

const DOMAIN = '.yanadoo.co.kr'

const setCookieForSubDomain = (name, value, daysToExpire) => {
  const expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + daysToExpire)

  const encodedValue = encodeURIComponent(value)
  const cookieString = `${name}=${encodedValue}; path=/; domain=${DOMAIN}; SameSite=None; Secure; expires=${expireDate.toGMTString()};`

  document.cookie = cookieString
}

function deleteCookie(cName) {
  const expireDate = new Date()

  // 어제 날짜를 쿠키 소멸 날짜로 설정한다.
  expireDate.setDate(expireDate.getDate() - 1)
  document.cookie = `${cName}=; expires=${expireDate.toGMTString()}; cross-site-cookie=bar; SameSite=None; Secure; path=/`
}

function deleteCookieForSubDomain(cName) {
  const expireDate = new Date()

  // 어제 날짜를 쿠키 소멸 날짜로 설정한다.
  expireDate.setDate(expireDate.getDate() - 1)
  document.cookie = `${cName}=; expires=${expireDate.toGMTString()}; SameSite=None; Secure; path=/; domain=${DOMAIN}`
}

export function userAgent() {
  // userAgent OS
  let userAgent = navigator.userAgent.toLowerCase()
  let returnUserAgent
  if (userAgent.search('android') > -1) {
    returnUserAgent = 'aos'
  } else if (
    userAgent.search('iphone') > -1 ||
    userAgent.search('ipod') > -1 ||
    userAgent.search('ipad') > -1 ||
    userAgent.search('macintosh') > -1
  ) {
    returnUserAgent = 'ios'
  }
  return returnUserAgent
}

// 공통 함수
function isEmpty(val) {
  return (
    val === undefined ||
    val === null ||
    val === '' ||
    (val !== null && typeof val === 'object' && !Object.keys(val).length)
  )
}

function isNotEmpty(val) {
  return !isEmpty(val)
}

export function callNative(iOSFunction, androidFunction, defaultFunction) {
  let device = userAgent()

  try {
    if (device === 'ios') {
      iOSFunction()
    } else if (device === 'aos') {
      androidFunction()
    } else if (isNotEmpty(defaultFunction)) {
      defaultFunction()
    } else {
      return
    }
  } catch (e) {
    if (isNotEmpty(defaultFunction)) {
      defaultFunction()
    }
  }
}

export function callIOS(message) {
  window.webkit.messageHandlers.yndiOSScriptHandler.postMessage(message)
}

export function setTitle(title) {
  if (isEmpty(title)) {
    return
  }

  callNative(
    function () {
      callIOS({ action: 'setTitle', title: title })
    },
    function () {
      window.Android.setTitle(title)
    }
  )
}

export function openExternalURL(url) {
  callNative(
    function () {
      callIOS({ action: 'openExternalURL', value: url })
    },
    function () {
      window.Android.openExternalURL(url)
    }
  )
}

export function hideTitleBar() {
  callNative(
    () => callIOS({ action: 'hideTitleBar' }),
    () => window.Android.hideTitleBar()
  )
}
export function showTitleBar() {
  callNative(
    () => callIOS({ action: 'showTitleBar' }),
    () => window.Android.showTitleBar()
  )
}

export function closeWebView() {
  callNative(
    function () {
      callIOS({ action: 'closeWebView' })
    },
    function () {
      window.Android.closeWebView()
    }
  )
}

// SNS 공유하기 함수 입니다. 웹뷰에서 공유하기 시도 시 네이티브
// shareType : kakaotalk(카카오톡), kakaostory(카카오스토리), band(네이버밴드)
// shareUrl : 공유할 URL
// shareText : 공유 문구
export function NativeSahreSNS(shareType, shareUrl, shareText, shareImage) {
  callNative(
    function () {
      callIOS({
        action: 'callShare',
        type: shareType,
        url: shareUrl,
        text: shareText,
        image: shareImage
      })
    },
    function () {
      window.Android.callShare(shareType, shareUrl, shareText, shareImage)
    }
  )
}

export function loadURL(path) {
  callNative(
    function () {
      callIOS({ action: 'loadURL', url: `${API_HOST}${path}` })
    },
    function () {
      window.Android.loadURL(`${API_HOST}${path}`)
    }
  )
}

export function openKakao() {
  callNative(
    function () {
      callIOS({ action: 'callKakao' })
    },
    function () {
      window.Android.callKakao()
    }
  )
}

export function webviewHistorySave() {
  const locationValue = window.location.origin + window.location.pathname
  let pageDepthCheck = 1
  for (let i = 1; i < 10; i++) {
    if (getCookie(`pageDepth${i}`) && getCookie(`pageDepth${i}`) !== '') pageDepthCheck++
  }

  const prevLocation = getCookie(`pageDepth${pageDepthCheck - 1}`)

  if (prevLocation !== locationValue) {
    if (pageDepthCheck === 1) {
      setCookie(`pageDepth1`, locationValue)
      setCookieForSubDomain(`pageDepth1`, locationValue)
    } else {
      setCookie(`pageDepth${pageDepthCheck}`, locationValue)
      setCookieForSubDomain(`pageDepth${pageDepthCheck}`, locationValue)
    }
  }
}

// 웹뷰 뒤로가기 함수
export function webviewHistoryBack(url) {
  console.log(`🚀 히스토리 백 URL: ${url}`)

  let pageDepthCheck = 1
  let pageBackUrl = ''

  // 페이지 깊이 확인
  for (let i = 1; i < 10; i++) {
    const cookieValue = getCookie(`pageDepth${i}`)
    console.log(`🔍 페이지 깊이 쿠키 확인 pageDepth${i}: ${cookieValue}`)
    if (cookieValue) {
      pageDepthCheck++
    } else {
      console.log(`🔍 pageDepth${i} 쿠키가 없습니다, 반복문 종료.`)
      break // 빈 쿠키를 만나면 반복문 종료
    }
  }
  console.log(`🔍 최종 pageDepthCheck 값: ${pageDepthCheck}`)

  // 뒤로 갈 URL 설정
  const previousPageDepth = getCookie(`pageDepth${pageDepthCheck - 2}`)
  console.log(`🔍 이전 페이지 깊이 쿠키 값: ${previousPageDepth}`)

  if (isEmpty(url)) {
    if (!isEmpty(previousPageDepth)) {
      const allowedDomains = ['https://api.yanadoo.co.kr', 'https://dev.yanadoo.co.kr']
      const isAllowedDomain = allowedDomains.some((domain) => previousPageDepth.startsWith(domain))
      console.log(`🔍 이전 페이지 깊이에 대한 허용된 도메인 여부: ${isAllowedDomain}`)
      pageBackUrl = isAllowedDomain
        ? `/webview${previousPageDepth.split('/webview')[1]}`
        : previousPageDepth
    } else {
      console.log('🔍 이전 페이지 깊이를 찾을 수 없어 기본 pageBackUrl 설정.')

      // 현재 페이지 URL에 historyBack 쿼리스트링이 있는지 확인
      const currentUrlParams = new URLSearchParams(window.location.search)
      if (currentUrlParams.has('historyBack')) {
        console.log('🔍 현재 페이지에 historyBack 쿼리스트링이 있으므로 해당 URL로 이동합니다.')
        pageBackUrl = currentUrlParams.get('historyBack')
      } else {
        pageBackUrl = '/webview' // 기본 URL 설정
      }
    }
  } else {
    pageBackUrl = url
  }

  console.log(`🚀 최종 pageBackUrl: ${pageBackUrl}`)

  // 현재 깊이의 쿠키 삭제
  console.log(`🔍 쿠키 삭제 pageDepth${pageDepthCheck - 1}`)
  deleteCookie(`pageDepth${pageDepthCheck - 1}`)
  deleteCookieForSubDomain(`pageDepth${pageDepthCheck - 1}`)
}
