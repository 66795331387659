import { useEffect, useState, useMemo, useCallback } from 'react'

import api from 'common/api'

export default function useGetFaqFilter(keyword) {
  const [allList, setAllList] = useState([])
  const [faqList, setFaqList] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchFaqList = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const response = await api.get(`/v2/faq/list?faqGroup=ALL&size=999`)
      const data = response.data.data
      setAllList(data.content)
    } catch (e) {
      console.error('fetch faq list error:', e)
      setError(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const listFilter = useMemo(() => {
    if (keyword.trim() !== '') {
      return allList.filter(
        (item) =>
          item.title.toLowerCase().includes(keyword.toLowerCase()) || item.aiKeyword === keyword
      )
    }
    return allList
  }, [keyword, allList])

  useEffect(() => {
    fetchFaqList()
  }, [fetchFaqList])

  useEffect(() => {
    setFaqList(listFilter)
  }, [listFilter])

  return { faqList, loading, error }
}
