import React from 'react'

import s from './Chat.module.scss'
import ChatForm from './ChatForm'
import FaqCounselGroup from './FaqCounselGroup'

export default function ChatFooter({
  addToFeed,
  postPromptMessage,
  isAiResponse,
  isPlatform,
  setTerminate
}) {
  return (
    <section className={s.chatFooter}>
      <div className={s.footerInner}>
        <FaqCounselGroup isPlatform={isPlatform} setTerminate={setTerminate} />
        <ChatForm
          addToFeed={addToFeed}
          postPromptMessage={postPromptMessage}
          isAiResponse={isAiResponse}
        />
      </div>
    </section>
  )
}
