import { useCallback, useState } from 'react'

import useChatFeedListStore from 'store/useChatFeedListStore'

import api from 'common/api'

import { AI_FAQ_CONFIG } from '../config'

export default function useAiFeeds({ gubun }) {
  const { AI_FAQ_CHAT } = AI_FAQ_CONFIG
  const { setFeedList, resetFeedList } = useChatFeedListStore()
  const [isAiResponse, setIsAiResponse] = useState(false)

  const addToFeed = useCallback(
    (feed) => {
      if (feed.type === 'USER' && !feed.responseOff) setIsAiResponse(true)
      setFeedList({ type: feed.type, message: feed.message, subContents: feed.subContents })
    },
    [setFeedList]
  )

  const fetchMessage = useCallback(
    async (message, type = 'AI') => {
      try {
        setIsAiResponse(true)
        const response = await api.get(`${AI_FAQ_CHAT}?message=${message}&gubun=${gubun}`)
        const data = response.data
        if (!data || data === '') {
          addToFeed({ type, message: '죄송합니다. 다시 한번 질문해 주세요.' })
        } else {
          addToFeed({
            type,
            message: data.content,
            subContents: data.subContents ? data.subContents : []
          })
        }
      } catch (e) {
        console.error(`fetch message error (${type}): `, e)
      } finally {
        setIsAiResponse(false)
      }
    },
    [addToFeed, AI_FAQ_CHAT]
  )

  const postPromptMessage = useCallback(
    async ({ message }) => {
      await fetchMessage(message)
    },
    [fetchMessage]
  )

  const initPromptMessage = useCallback(async () => {
    await fetchMessage('Hi')
  }, [fetchMessage])

  return {
    addToFeed,
    initPromptMessage,
    isAiResponse,
    postPromptMessage,
    resetFeedList
  }
}
