import React from 'react'

import MessageAi from './MessageAi'
import MessageUser from './MessageUser'
import SubContentsItem from './SubContentsItem'
import RelatedFaq from './RelatedFaq'

export default function ChatFeedItem({ type, message, pageType, subContents, addToFeed }) {
  const handleSubContent = (q, a) => {
    addToFeed({ type: 'USER', message: q, responseOff: true })
    setTimeout(() => {
      addToFeed({ type: 'AI', message: a })
    }, 500)
  }
  if (type === 'AI') {
    return (
      <>
        <MessageAi message={message} pageType={pageType} subContents={subContents} />
        {subContents.length > 0 && (
          <RelatedFaq subContents={subContents} handleSubContent={handleSubContent} />
        )}
      </>
    )
  } else {
    return <MessageUser message={message} />
  }
}
