import { useState } from 'react'

import ModalPortal from 'ModalPortal'
import { loadURL, openExternalURL, showTitleBar } from 'webview/bridge'

import { YANADOO_HOST } from 'common/config'
import ModalMyInquiry from 'components/modal/ModalMyInquiry'

import s from './FaqCounselGroup.module.scss'

export default function FaqCounselGroup({ isPlatform, setTerminate }) {
  const [inquiry, setInquiry] = useState(false)

  const onClickInquiry = () => {
    if (isPlatform) {
      setTerminate(true)
      showTitleBar()
      loadURL(
        `/webview/mypage/qna/inquiryWrite?historyBack=${YANADOO_HOST}/webview/service/faq/chat?service=YANADOO`
      )
    } else {
      setInquiry(!inquiry)
    }
  }

  const onClickChannel = () => {
    if (isPlatform) {
      openExternalURL('https://yanadoo.channel.io/workflows/690327')
    } else {
      window.open('https://yanadoo.channel.io/workflows/690327', '_blank')
    }
  }

  return (
    <>
      <div className={s.customerUtils}>
        <i>고객센터 간편 링크</i>
        <div className={s.utilButtons}>
          <button onClick={onClickChannel}>상품 관련 문의</button>
          <button onClick={onClickInquiry}>오류 및 서비스 문의</button>
        </div>
      </div>
      {inquiry && (
        <ModalPortal>
          <ModalMyInquiry btnClose handleCloseModal={() => setInquiry(false)} />
        </ModalPortal>
      )}
    </>
  )
}
