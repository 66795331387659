import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'

import AuthService from 'services/authService'

import ModalMyInquiry from 'components/modal/ModalMyInquiry'
import useModalLogin from 'store/useModalLogin'

import MobileTitleBar from './components/MobileTitleBar'
import SectionCSSearch from './components/SectionCSSearch'
import SectionFaq from './components/SectionFaq'
import SectionHelp from './components/SectionHelp'
import SectionNotice from './components/SectionNotice'
import SectionSurvey from './components/SectionSurvey'

import s from './Service.module.scss'

export default function CustomerService({ isPlatform }) {
  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search)
  // const userSeq = queryParams.get('userSeq')
  // const classSeq = queryParams.get('classSeq')
  // const lectureSeq = queryParams.get('lectureSeq')
  const navigate = useNavigate()
  const [inquiry, setInquiry] = useState(false)
  const { setIsModalLogin } = useModalLogin()
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const handleInquiry = () => {
    if (!isPlatform) {
      if (!AuthService.getUserInfo()) {
        setIsModalLogin(true)
        return
      }
    }

    setInquiry(true)
  }

  const handleBackButton = () => {
    if (!isPlatform) {
      navigate(-1)
    } else {
      if (document.referrer === '') {
        console.log('닫기')
      } else {
        navigate(-1)
      }
    }
  }

  return (
    <>
      {isMobile && !isPlatform && <MobileTitleBar title="고객센터" onClick={handleBackButton} />}
      <article className={s.csWrap}>
        <SectionCSSearch />
        <SectionFaq />
        <SectionHelp handleInquiry={handleInquiry} />
        <SectionNotice />
        <SectionSurvey />
      </article>
      {inquiry && <ModalMyInquiry btnClose handleCloseModal={() => setInquiry(false)} />}
    </>
  )
}
