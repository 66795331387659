import React from 'react'

import s from './ChatTopFaq.module.scss'
import { AI_FAQ_CONFIG } from '../config'

export default function ChatTopFaq() {
  return (
    <div className={s.chatTopFaq}>
      <img className={s.faqThumb} src={AI_FAQ_CONFIG.AI_PROFILE_THUMB} alt="ai 상담원" />
      <hgroup className={s.faqTitle}>
        <span className={s.faqSmallTitle}>365일, 24시간 언제든지!</span>
        <h5 className={s.faqBigTitle}>무엇을 도와드릴까요?</h5>
      </hgroup>
    </div>
  )
}
