import React from 'react'
import { useNavigate } from 'react-router'

import cx from 'classnames'

import { CS_CONFIG } from '../config'
import s from '../Service.module.scss'

export default function SectionHelp({ handleInquiry }) {
  const navigate = useNavigate()
  const onClickCounsel = (service) => {
    navigate(`/service/faq/ai?service=${service}`)
  }
  return (
    <section className={s.csHelp}>
      <div className={s.csInner}>
        <h3 className={s.sectionTitle}>도움이 필요하신가요?</h3>
        <div className={s.helpCard}>
          <div className={s.helpAi}>
            <div className={s.helpAiInner}>
              <h5>AI 상담원</h5>
              <img
                src={`${CS_CONFIG.CS_CDN_HOST}img_ai_talk.png`}
                alt="365일, 24시간 언제든지 AI 상담원에게 궁금한 점을 물어보세요!"
                className={s.pcImg}
              />
              <p className={s.mTxt}>
                365일, 24시간 언제든지
                <br />
                AI 상담원에게 궁금한 점을 물어보세요!
              </p>
              <div className={s.btns}>
                <button
                  type="button"
                  className={s.btnAiCounsel}
                  onClick={() => onClickCounsel('YANADOO')}
                >
                  야나두 문의
                </button>
                <button
                  type="button"
                  className={s.btnAiCounsel}
                  onClick={() => onClickCounsel('YAFIT')}
                >
                  야핏 사이클 문의
                </button>
              </div>
            </div>
          </div>
          <div className={s.helpCounsel}>
            <div className={s.counselCont}>
              <dl>
                <dt>문의하기</dt>
                <dd>질문을 남겨주시면 꼼꼼히 확인할게요</dd>
              </dl>
              <button type="button" className={cx(s.counselBtn)} onClick={() => handleInquiry()}>
                1:1 문의 등록
              </button>
            </div>
            <div className={s.kakaoCont}>
              <dl>
                <dt>카카오톡 상담</dt>
                <dd>상담 가능 시간 : 평일 오전 9시~오후6시 (주말, 공휴일 제외) </dd>
              </dl>
              <div className={s.kakaoBtns}>
                <a
                  href="https://pf.kakao.com/_xeWxjMu"
                  target="_blank"
                  rel="noreferrer"
                  className={cx(s.counselBtn)}
                >
                  <span>야나두 상담</span>
                </a>
                <a
                  href="https://pf.kakao.com/_xmCkrG"
                  target="_blank"
                  rel="noreferrer"
                  className={cx(s.counselBtn)}
                >
                  <span>야핏 사이클 상담</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
