import ReactMarkdown from 'react-markdown'

import rehypeRaw from 'rehype-raw'

import s from './Chat.module.scss'
import { AI_FAQ_CONFIG } from '../config'

export default function MessageAi({ message, subContents }) {
  return (
    <div className={s.aiMessage}>
      <div className={s.msgThumb}>
        <img src={AI_FAQ_CONFIG.AI_PROFILE_THUMB} alt="AI 상담원" />
      </div>
      <dl>
        <dt className={s.msgName}>{'AI 상담원'}</dt>
        <dd className={s.message}>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              a: ({ node, ...props }) => (
                <a {...props} className={s.link} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              )
            }}
          >
            {message}
          </ReactMarkdown>
        </dd>
      </dl>
    </div>
  )
}
