import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { closeWebView, webviewHistoryBack } from 'webview/bridge'

import s from './WebviewCustomer.module.scss'

export default function CustomerTitleBar({ title, type, onBtnClick }) {
  const navigate = useNavigate()

  useEffect(() => {
    window.pressedBackButton = function () {
      pressedBackButton()
    }

    return () => {
      delete window.pressedBackButton
    }
  }, [])

  const pressedBackButton = () => {
    if (onBtnClick) {
      webviewHistoryBack()
      onBtnClick()
    } else {
      switch (type) {
        case 'close':
          console.log('webview close')
          webviewHistoryBack()
          closeWebView()
          break
        default:
          console.log('navigate history back')
          webviewHistoryBack()
          navigate(-1)
          break
      }
    }
  }
  return (
    <hgroup className={s.customerTitleBar}>
      <button className={s.btnBack} onClick={pressedBackButton}>
        back/close
      </button>
      <h1 className={s.title}>{title}</h1>
    </hgroup>
  )
}
